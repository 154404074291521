import React from "react";
import {Route, Routes} from 'react-router-dom';

import handleDefaultExporter from "../helpers/defaultExporter";
import PrivateRoute from "./PrivateRoute";
import PATHS from "../helpers/paths";

const ErrorPage = React.lazy(() => import("./common/ErrorPage"));

const Dashboard = React.lazy(() => import("./Dashboard/index"))

const LoginPage = React.lazy(() => import("./Login"));
const LogoutPage = React.lazy(() => import("./Logout"));
const PasswordResetPage = React.lazy(() => import("./PasswordReset"));

const SystemAddUserPage = React.lazy(() => import("./system/AddUser"));
const SystemManageUsersPage = React.lazy(() => import("./system/ManageUsers"));
const EntityAddCrewPage = React.lazy(() => import("./entity/Crews/AddCrew"));
const EntityManageCrewsPage = React.lazy(() => import("./entity/Crews/ManageCrews"));

const EntityAddAccountPage = React.lazy(() => import("./Dashboard/AddAccount"));

const WorkLogReportPage = React.lazy(() => import("./reports/WorkLog"));

const LegacyReportsManageUserAccess = React.lazy(() => import("./LegacyReports/ManageUserAccess"));
const LegacyReportsManageWorkDetailsAccess = React.lazy(() => import("./LegacyReports/ManageWorkDetailsAccess"));
const LegacyReportsManageSupervisors = React.lazy(() => import("./LegacyReports/ManageSupervisors"));
const LegacyReportsSupervisorManageIWL = React.lazy(() => import("./LegacyReports/supervisor/ManageIWL"));
const LegacyReportsReportsPage = React.lazy(() => import("./LegacyReports/reports/ReportsHandler"));

const EntityDeviceAdd = React.lazy(() => import("./entity/Devices/Add"))
const EntityDeviceManage = React.lazy(() => import("./entity/Devices/Manage"))
// const EntityViewDevicesPage = React.lazy(() => import("./entity/Devices/ViewDevices"));
const EntityAddDeviceSettingsPage = React.lazy(() => import("./entity/Devices/AddSettings"));
const EntityViewDeviceSettingsPage = React.lazy(() => import("./entity/Devices/ViewDeviceSettings"));
const EntityViewDeviceSettingsHistoryPage = React.lazy(() => import("./entity/Devices/ViewDeviceSettingsHistory"));
const EntityEditSettingsPage = React.lazy(() => import("./entity/Devices/EditSettings"));
const EntityViewConfigurationProfilesPage = React.lazy(() => import("./entity/Devices/ViewConfigurationProfiles"));
// const EntityAddDevicesPage = React.lazy(() => import("./entity/Devices/AddDevices"));

const HpayCoreWorkersAdd = React.lazy(() => import("./entity/Workers/Add"));
const HpayCoreWorkersManage = React.lazy(() => import("./entity/Workers/Manage"));
const HpayCoreWorkersBulkManagement = React.lazy(() => import("./entity/Workers/BulkManagement"));

const HpayCoreContractsAdd = React.lazy(() => import("./entity/Contracts/Add"));
const HpayCoreContractsManage = React.lazy(() => import("./entity/Contracts/Manage"));

const HpayCoreH2AExceptionsAdd = React.lazy(() => import("./hpay_core/H2AExceptions/Add"));
const HpayCoreH2AExceptionsManage = React.lazy(() => import("./hpay_core/H2AExceptions/Manage"));

const HpayCoreWorkDetailAdd = React.lazy(() => import("./hpay_core/WorkDetails/Add"));
const HpayCoreWorkDetailView = React.lazy(() => import("./hpay_core/WorkDetails/Manage"));
const HpayCoreWorkDetailEdit = React.lazy(() => import("./hpay_core/WorkDetails/Edit"));
const HpayCoreWorkDetailManageAliases = React.lazy(() => import("./hpay_core/WorkDetails/ManageAliases"));
const HpayCoreWorkDetailBulkOperations = React.lazy(() => import("./hpay_core/WorkDetails/BulkOperations"));

const ToolsAccessManager = React.lazy(() => import("./tools/AccessManager"));
const ToolsDataEntriesManager = React.lazy(() => import("./tools/TimeSheetManager"));
const ToolsV3Migration = React.lazy(() => import("./tools/V3Migration"))

const ReportBuilder = React.lazy(() => import("./ReportBuilder/index"));

const Status = React.lazy(() => import("./Status"));
const DynamicStatusDetails = React.lazy(() => import("./StatusDetails"));

// Reports
const ManageReports = React.lazy(() => import("./reports/ManageReports"));
const DailyReport = React.lazy(() => import("./reports/DailyReport"));
const V3Reports = React.lazy(() => import("./reports/V3Reports"));

// BlackBox
const BBCardSetup = React.lazy(() => import("./tools/BlackBox/CardSetup"));

const Pages = (props) => {
    return (<div style={{padding: '3rem'}}>
        <Routes>
            <Route element={<PrivateRoute/>}>

                {/* ================ Logout page ================*/}
                <Route exact path={PATHS.LOGOUT} element={<LogoutPage/>}/>

                {/* ================ Password Reset page ================*/}
                <Route exact path={PATHS.PASSWORD_RESET} element={<PasswordResetPage/>}/>

                {/* ================ Dashboard page ================*/}
                <Route exact path={PATHS.DASHBOARD} element={<Dashboard/>}/>

                {/* ================ Old Dashboard page ================*/}
                {/*<Route exact path={PATHS.DASHBOARD_OLD} element={<DashboardPage/>}/>*/}

                {/* ================ System add user page ================*/}
                <Route exact path={PATHS.SYSTEM_ADD_USER} element={<SystemAddUserPage/>}/>

                {/* ================ System manage users page ================*/}
                <Route exact path={PATHS.SYSTEM_MANAGE_USERS} element={<SystemManageUsersPage/>}/>

                {/* ================ System add account page ================*/}
                <Route exact path={PATHS.ENTITY_ADD_ACCOUNT} element={<EntityAddAccountPage/>}/>

                {/* ================ Entity add crew page ================*/}
                <Route exact path={PATHS.ENTITY_ADD_CREW} element={<EntityAddCrewPage/>}/>

                {/* ================ Entity manage crews page ================*/}
                <Route exact path={PATHS.ENTITY_MANAGE_CREWS} element={<EntityManageCrewsPage/>}/>

                {/* ================ Entity add devices page ================*/}
                <Route exact path={PATHS.ENTITY_DEVICE_ADD} element={<EntityDeviceAdd/>}/>

                {/* ================ Entity manage devices page ================*/}
                <Route exact path={PATHS.ENTITY_DEVICE_MANAGE} element={<EntityDeviceManage/>}/>

                {/* ================ Entity view devices page ================*/}
                {/*<Route exact path={PATHS.ENTITY_VIEW_DEVICES} element={<EntityViewDevicesPage/>}/>*/}

                {/* ================ Entity add Devices settings page ================*/}
                <Route exact path={PATHS.ENTITY_ADD_DEVICE_SETTINGS} element={<EntityAddDeviceSettingsPage/>}/>

                {/* ================ Entity view Devices settings by SID page ================*/}
                <Route exact path={PATHS.ENTITY_VIEW_DEVICE_SETTINGS} element={<EntityViewDeviceSettingsPage/>}/>

                {/* ================ Entity view Devices settings history by SID page ================*/}
                <Route exact path={PATHS.ENTITY_VIEW_DEVICE_SETTINGS_HISTORY}
                       element={<EntityViewDeviceSettingsHistoryPage/>}/>

                {/* ================ Entity view Devices settings history by SID/setting by setting id page ================*/}
                <Route exact path={PATHS.ENTITY_VIEW_DEVICE_SETTINGS_HISTORY_BY_ID}
                       element={<EntityViewDeviceSettingsHistoryPage/>}/>

                {/* ================ Entity edit Devices settings by SID page ================*/}
                <Route exact path={PATHS.ENTITY_EDIT_DEVICE_SETTINGS} element={<EntityEditSettingsPage/>}/>

                {/* ================ Entity edit settings by config id page ================*/}
                <Route exact path={PATHS.ENTITY_EDIT_CONFIG_SETTINGS} element={<EntityEditSettingsPage/>}/>

                {/* ================ Entity view Devices settings configuration profiles ================*/}
                <Route exact path={PATHS.ENTITY_VIEW_CONFIGURATION_PROFILES}
                       element={<EntityViewConfigurationProfilesPage/>}/>

                {/* ================ Entity add devices page ================*/}
                {/*<Route exact path={PATHS.ENTITY_ADD_DEVICES} element={<EntityAddDevicesPage/>}/>*/}

                {/* ================ Workers: Add worker page ================*/}
                <Route exact path={PATHS.HPAY_CORE_WORKERS_ADD} element={<HpayCoreWorkersAdd/>}/>

                {/* ================ Workers: Manage workers page ================*/}
                <Route exact path={PATHS.HPAY_CORE_WORKERS_MANAGE} element={<HpayCoreWorkersManage/>}/>

                {/* ================ Workers: Manage bulk operations ================*/}
                <Route exact path={PATHS.HPAY_CORE_WORKERS_BULK_MANAGEMENT} element={<HpayCoreWorkersBulkManagement/>}/>

                {/* ================ Contracts: Add contract page ================*/}
                <Route exact path={PATHS.HPAY_CORE_CONTRACTS_ADD} element={<HpayCoreContractsAdd/>}/>

                {/* ================ Contracts: Manage contracts page ================*/}
                <Route exact path={PATHS.HPAY_CORE_CONTRACTS_MANAGE} element={<HpayCoreContractsManage/>}/>

                {/* ================ H2A Exceptions: Add H2A exception page ================*/}
                <Route exact path={PATHS.HPAY_CORE_H2A_EXCEPTIONS_ADD} element={<HpayCoreH2AExceptionsAdd/>}/>

                {/* ================ H2A Exceptions: Manage H2A exception page ================*/}
                <Route exact path={PATHS.HPAY_CORE_H2A_EXCEPTIONS_MANAGE} element={<HpayCoreH2AExceptionsManage/>}/>

                {/* ================ Work Detail: Add work detail ================*/}
                <Route exact path={PATHS.HPAY_CORE_WORK_DETAIL_ADD} element={<HpayCoreWorkDetailAdd/>}/>

                {/* ================ Work Detail: View work detail ================*/}
                <Route exact path={PATHS.HPAY_CORE_WORK_DETAIL_VIEW} element={<HpayCoreWorkDetailView/>}/>

                {/* ================ Work Detail: Edit work detail by key ================*/}
                <Route exact path={PATHS.HPAY_CORE_WORK_DETAIL_EDIT_DYNAMIC} element={<HpayCoreWorkDetailEdit/>}/>

                {/* ================ Work Detail: Manage Aliases ================*/}
                <Route exact path={PATHS.HPAY_CORE_WORK_DETAIL_MANAGE_ALIASES}
                       element={<HpayCoreWorkDetailManageAliases/>}/>

                {/* ================ Work Detail: Manage Bulk Operations ================*/}
                <Route exact path={PATHS.HPAY_CORE_WORK_DETAIL_BULK_OPERATIONS}
                       element={<HpayCoreWorkDetailBulkOperations/>}/>

                {/* ================ Tools: Access Manager ================*/}
                <Route exact path={PATHS.TOOL_ACCESS_MANAGER} element={<ToolsAccessManager/>}/>

                {/* ================ Tools: Work Entry Manager ================*/}
                <Route exact path={PATHS.TOOL_DATA_ENTRIES_MANAGER} element={<ToolsDataEntriesManager/>}/>

                {/* ================ Tools: V3 Migration ================*/}
                <Route exact path={PATHS.TOOL_V3_MIGRATION} element={<ToolsV3Migration/>}/>

                {/* ================ Tools: BlackBox Get Card Data ================*/}
                <Route exact path={PATHS.TOOL_BLACK_BOX_CARD_SETUP} element={<BBCardSetup/>}/>

                {/* ================ LegacyReports : Manage User Access ================*/}
                <Route exact path={PATHS.LEGACY_REPORTS_MANAGE_USER_ACCESS} element={<LegacyReportsManageUserAccess/>}/>

                {/* ================ LegacyReports : Manage Supervisors ================*/}
                <Route exact path={PATHS.LEGACY_REPORTS_MANAGE_SUPERVISORS}
                       element={<LegacyReportsManageSupervisors/>}/>

                {/* ================ LegacyReports : Manage User Access ================*/}
                <Route exact path={PATHS.LEGACY_REPORTS_MANAGE_WORK_DETAILS_ACCESS}
                       element={<LegacyReportsManageWorkDetailsAccess/>}/>

                {/* ================ LegacyReports : Supervisor Manage Ignore Worker List ================*/}
                <Route exact path={PATHS.LEGACY_REPORTS_SUPERVISOR_MANAGE_IWL}
                       element={<LegacyReportsSupervisorManageIWL/>}/>

                {/* ================ LegacyReports Report by key page ================*/}
                <Route exact path={PATHS.LEGACY_REPORTS_REPORT} element={<LegacyReportsReportsPage/>}/>

                {/* ================ Report Builder ================*/}
                <Route exact path={PATHS.REPORT_BUILDER} element={<ReportBuilder/>}/>


                {/* ===================******************* Reports start here *******************===================*/}
                {/* ===================**********************************************************===================*/}

                {/* ================ Reports : Work Log page ================*/}
                <Route exact path={PATHS.REPORTS_WORK_LOG} element={<WorkLogReportPage/>}/>

                {/* ================ Reports : Manage Reports page ================*/}
                <Route exact path={PATHS.REPORTS_MANAGEMENT} element={<ManageReports/>}/>

                {/* ================ Reports : Daily Report page ================*/}
                <Route exact path={PATHS.REPORTS_DAILY_REPORT} element={<DailyReport/>}/>

                {/* ================ Reports : Dynamic V3 reports ================*/}
                <Route exact path={PATHS.V3_REPORTS_REPORT} element={<V3Reports/>}/>

                {/* ===================******************* Reports end here *******************===================*/}
                {/* ===================********************************************************===================*/}
                x
                {/* ================ Status Secret Page ================*/}
                <Route exact path={PATHS.STATUS} element={<Status/>}/>

                {/* ================ Dynamic Status Details Secret Page ================*/}
                <Route exact path={PATHS.DYNAMIC_STATUS_DETAILS} element={<DynamicStatusDetails/>}/>
            </Route>

            <Route exact path={PATHS.HOME} element={<LoginPage/>}/>

            <Route exact path={PATHS.LOGIN} element={<LoginPage/>}/>

            <Route path="*" element={<ErrorPage/>}/>
        </Routes>
    </div>);
}

export default handleDefaultExporter(Pages);
