//Frontend paths mainly to be used in redirecting user from one place to another
const PATHS = {
    HOME: "/",
    DASHBOARD: "/dashboard",

    //Account related links
    REGISTER: "/account/register",
    LOGIN: "/account/login",
    LOGOUT: "/account/logout",
    PASSWORD_RESET: "account/passwordreset",

    //System links
    SYSTEM_ADD_USER: "/system/AddUser",
    SYSTEM_MANAGE_USERS: "/system/ManageUsers",
    SYSTEM_ADD_MULTIPLE_USERS: "/system/AddMultipleUsers",
    SYSTEM_VIEW_USER: "/system/ViewUser",

    //Entity links
    ENTITY_ADD_ACCOUNT: "/entity/AddAccount",
    ENTITY_ADD_CREW: "/entity/AddCrew",
    ENTITY_MANAGE_CREWS: "/entity/ManageCrews",

    // Devices
    ENTITY_DEVICE_ADD: "/entity/Devices/Add",
    ENTITY_DEVICE_MANAGE: "/entity/Devices/Manage",
    ENTITY_VIEW_DEVICES: "/entity/ViewDevices",
    ENTITY_ADD_DEVICE_SETTINGS: "/entity/AddDeviceSettings",
    ENTITY_VIEW_CONFIGURATION_PROFILES: "/entity/ViewConfigurationProfiles",
    ENTITY_VIEW_DEVICE_SETTINGS: "/entity/ViewDeviceSettings/:sid",
    ENTITY_VIEW_DEVICE_SETTINGS_HISTORY: "/entity/ViewDeviceSettingsHistory/:sid",
    ENTITY_VIEW_DEVICE_SETTINGS_HISTORY_BY_ID: "/entity/ViewDeviceSettingsHistory/:sid/:sh_id",
    ENTITY_EDIT_DEVICE_SETTINGS: "/entity/EditDeviceSettings/:sid",
    ENTITY_EDIT_CONFIG_SETTINGS: "/entity/EditConfigSettings/:config_id",
    ENTITY_ADD_DEVICES: "/entity/AddDevices",

    // Reports
    REPORTS: "/reports",
    REPORTS_MANAGEMENT: "/reports/Manage",
    REPORTS_WORK_LOG: "/reports/WorkLog",
    REPORTS_DAILY_REPORT: "/reports/DailyReport",
    V3_REPORTS_REPORT: "/reports/:report_key",
    DYNAMIC_V3_REPORTS_REPORT: "/reports",

    // Legacy Reports
    LEGACY_REPORTS_MANAGE_USER_ACCESS: "/LegacyReports/ManageUserAccess",
    LEGACY_REPORTS_MANAGE_SUPERVISORS: "/LegacyReports/ManageSupervisors",
    LEGACY_REPORTS_MANAGE_WORK_DETAILS_ACCESS: "/LegacyReports/ManageWorkDetailsAccess",
    LEGACY_REPORTS_SUPERVISOR_MANAGE_IWL: "/LegacyReports/Supervisor/ManageIgnoreWorkerList",
    LEGACY_REPORTS_REPORT: "/LegacyReports/Report/:report_key",

    // dynamic path
    DYNAMIC_VIEW_DEVICE_SETTINGS: "/entity/ViewDeviceSettings",
    DYNAMIC_VIEW_DEVICE_SETTINGS_HISTORY: "/entity/ViewDeviceSettingsHistory",
    DYNAMIC_EDIT_DEVICE_SETTINGS: "/entity/EditDeviceSettings",
    DYNAMIC_EDIT_CONFIG_SETTINGS: "/entity/EditConfigSettings",
    DYNAMIC_LEGACY_REPORTS_REPORT: "/LegacyReports/Report",

    // Tools
    TOOL_ACCESS_MANAGER: "/tools/AccessManager",
    TOOL_DATA_ENTRIES_MANAGER: "/tools/TimeEntriesManager",
    TOOL_V3_MIGRATION: "/tools/V3Migration",

    // Blackbox tools
    TOOL_BLACK_BOX_CARD_SETUP: "/tools/BlackBox/CardSetup",

    // Work Details
    HPAY_CORE_WORK_DETAIL_ADD: "/core/WorkDetails/Add",
    HPAY_CORE_WORK_DETAIL_VIEW: "/core/WorkDetails/View",
    HPAY_CORE_WORK_DETAIL_MANAGE_ALIASES: "/core/WorkDetails/ManageAliases",
    HPAY_CORE_WORK_DETAIL_EDIT_DYNAMIC: "/core/WorkDetails/Edit/:wd_id",
    HPAY_CORE_WORK_DETAIL_BULK_OPERATIONS: "/core/WorkDetails/BulkManagement",

    // Workers
    HPAY_CORE_WORKERS_ADD: "/core/Workers/Add",
    HPAY_CORE_WORKERS_MANAGE: "/core/Workers/Manage",
    HPAY_CORE_WORKERS_BULK_MANAGEMENT: "/core/Workers/BulkManagement",

    // Contracts
    HPAY_CORE_CONTRACTS_ADD: "/core/Contracts/Add",
    HPAY_CORE_CONTRACTS_MANAGE: "/core/Contracts/Manage",

    // H-2A Exceptions
    HPAY_CORE_H2A_EXCEPTIONS_ADD: "/core/H2AExceptions/Add",
    HPAY_CORE_H2A_EXCEPTIONS_MANAGE: "/core/H2AExceptions/Manage",

    // Report Builder
    REPORT_BUILDER: "/ReportBuilder",

    // Status
    STATUS: "status",
    DYNAMIC_STATUS_DETAILS: "status/:status_id",

    //To get key of path which is used in navigation menu
    getKeyOfPath: path => {
        switch (path) {
            case PATHS.DASHBOARD:
                return "199";
            // case PATHS.DASHBOARD_OLD:
            //     return "100";
            case PATHS.LOGOUT:
                return "101";
            case PATHS.PASSWORD_RESET:
                return "102";
            case PATHS.SYSTEM_ADD_USER:
                return "200";
            case PATHS.SYSTEM_MANAGE_USERS:
                return "201";
            case PATHS.SYSTEM_ADD_MULTIPLE_USERS:
                return "210";
            case PATHS.SYSTEM_VIEW_USER:
                return "220";
            case PATHS.ENTITY_ADD_ACCOUNT:
                return "300";
            case PATHS.ENTITY_ADD_CREW:
                return "310";
            case PATHS.ENTITY_MANAGE_CREWS:
                return "311";
            case PATHS.ENTITY_DEVICE_ADD:
                return "320";
            case PATHS.ENTITY_VIEW_DEVICES:
                return "321";
            case PATHS.ENTITY_ADD_DEVICE_SETTINGS:
                return "322";
            case PATHS.ENTITY_VIEW_DEVICE_SETTINGS:
                return "323";
            case PATHS.ENTITY_VIEW_CONFIGURATION_PROFILES:
                return "324";
            case PATHS.ENTITY_ADD_DEVICES:
                return "325";
            case PATHS.ENTITY_DEVICE_MANAGE:
                return "326";
            case PATHS.HPAY_CORE_WORKERS_ADD:
                return "330";
            case PATHS.HPAY_CORE_WORKERS_MANAGE:
                return "331";
            case PATHS.HPAY_CORE_WORKERS_BULK_MANAGEMENT:
                return "332";
            case PATHS.HPAY_CORE_CONTRACTS_ADD:
                return "340";
            case PATHS.HPAY_CORE_CONTRACTS_MANAGE:
                return "341";
            case PATHS.HPAY_CORE_H2A_EXCEPTIONS_ADD:
                return "350";
            case PATHS.HPAY_CORE_H2A_EXCEPTIONS_MANAGE:
                return "351";
            case PATHS.HPAY_CORE_WORK_DETAIL_ADD:
                return "400";
            case PATHS.HPAY_CORE_WORK_DETAIL_VIEW:
                return "401";
            case PATHS.HPAY_CORE_WORK_DETAIL_EDIT_DYNAMIC:
                return "402";
            case PATHS.HPAY_CORE_WORK_DETAIL_MANAGE_ALIASES:
                return "403";
            case PATHS.HPAY_CORE_WORK_DETAIL_BULK_OPERATIONS:
                return "404";
            case PATHS.REPORTS:
                return "500";
            case PATHS.REPORTS_MANAGEMENT:
                return "501";
            case PATHS.REPORTS_WORK_LOG:
                return "502";
            case PATHS.REPORTS_DAILY_REPORT:
                return "503";
            case PATHS.LEGACY_REPORTS_REPORT:
                return "550";
            case PATHS.LEGACY_REPORTS_MANAGE_USER_ACCESS:
                return "551";
            case PATHS.LEGACY_REPORTS_MANAGE_SUPERVISORS:
                return "552";
            case PATHS.LEGACY_REPORTS_SUPERVISOR_MANAGE_IWL:
                return "553";
            case PATHS.LEGACY_REPORTS_MANAGE_WORK_DETAILS_ACCESS:
                return "554";
            case PATHS.TOOL_DATA_ENTRIES_MANAGER:
                return "600";
            case PATHS.TOOL_V3_MIGRATION:
                return "601";
            case PATHS.TOOL_ACCESS_MANAGER:
                return "602";
            case PATHS.TOOL_BLACK_BOX_CARD_SETUP:
                return "603";
            case PATHS.REPORT_BUILDER:
                return "700";
            case PATHS.STATUS:
                return "998";
            case PATHS.DYNAMIC_STATUS_DETAILS:
                return "999";
            default:
                return "0";
        }
    },

    //To get key of submenu which is used in navigation menu
    getKeyOfSubMenu: path => {
        switch (path) {
            case PATHS.SYSTEM_ADD_USER:
                return "2000";
            case PATHS.ENTITY_ADD_ACCOUNT:
                return "3000";
            case PATHS.ENTITY_ADD_CREW:
                return "3010";
            case PATHS.ENTITY_ADD_DEVICE_SETTINGS:
                return "3020";
            case PATHS.HPAY_CORE_WORKERS_ADD:
                return "3030";
            case PATHS.HPAY_CORE_CONTRACTS_ADD:
                return "3040";
            case PATHS.HPAY_CORE_H2A_EXCEPTIONS_ADD:
                return "3050";
            case PATHS.HPAY_CORE_WORK_DETAIL_ADD:
                return "4000";
            case PATHS.REPORTS:
                return "5000";
            case PATHS.LEGACY_REPORTS_REPORT:
                return "5010";
            case PATHS.TOOL_DATA_ENTRIES_MANAGER:
                return "6000";
            case PATHS.REPORT_BUILDER:
                return "7000";
            case PATHS.STATUS:
                return "9998";
            case PATHS.DYNAMIC_STATUS_DETAILS:
                return "9999";
            default:
                return "0";
        }
    },

    generateDynamicPath: (pathKey, pathParams) => {
        return `${pathKey}/${pathParams.join("/")}`;
    },
};

export default PATHS;
